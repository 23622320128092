html {
    height: 100%;
    font-size: ($base-font-size / 16px) * 1em; /* [1] */
    //line-height: $base-line-height / $base-font-size; /* [1] */
    margin: 0 !important;
    padding: 0;
    color: $color-text;
}

body {
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #15395E;
}
