.u-1\/1 {
    width: 100% !important;
}

.u-1\/2 {
    width: 50% !important;
}

.u-1\/3 {
    width: 33.333333333% !important;
}

.u-2\/3 {
    width: 66.666666666% !important;
}


.container {
    max-width: 1248px;
}

//24px Gutter
.row {
    margin-right: -16px;
    margin-left: -16px;
}

.row>[class^="col-"],
.row>[class^=" col-"] {
    padding-right: 16px;
    padding-left: 16px;
}
