.o-page-footer {
    flex-shrink: 0;
    text-align: center;

    .o-page-footer__image {
        display: block;
        margin: 0 auto;
        width: 100%;
        max-width: 784px;
        height: auto;
        margin-top: 90px;
        margin-bottom: 64px;
        padding: 0 15px;
    }

    .o-page-footer__inner {
        background-color: $color-brand2;
        padding: 30px 30px 22px;

        @include media-breakpoint-down(md) {
            padding: 30px 0 22px;
        }
    }

    .o-page-footer_menu {
        display: block;
        color: $color-brand;
        text-align: center;
        margin: 0 auto;

        .nav__links {
            display: inline-block;
            list-style-type: none;
            margin: 0;
            padding: 0;

            li {
                display: inline-block;
                margin: 0;
                padding: 0;

                a {
                    transition: all 0.33s ease-in-out;
                    display: block;
                    color: $color-brand;
                    text-transform: uppercase;
                    font-family: "brandon-grotesque";
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 2.25px;
                    line-height: 18px;
                    padding: 0 8px;

                    @media (min-width: 320px) and (max-width: 412px) {
                        letter-spacing: 1px;
                        font-size: 11px;
                        padding: 0 4px;
                    }

                    &:hover {
                        transition: all 0.33s ease-in-out;
                        color: $white;
                        text-decoration: none;
                    }
                }
            }
        }

        .link--logo {
            display: inline-block;

            .link--logo--image {
                transition: all 0.33s ease-in-out;
                height: 64px;
                width: 64px;

                @media (min-width: 320px) and (max-width: 412px) {
                    height: 48px;
                    width: 48px;
                }

                #Style-Guide {
                    transition: all 0.33s ease-in-out;
                }

                &:hover {
                    transition: all 0.33s ease-in-out;

                    #Style-Guide {
                        transition: all 0.33s ease-in-out;
                        fill: $white;
                    }

                }
            }
        }
    }

    .social-links {
        display: block;
        margin: 20px auto;
        padding: 0;
        color: $color-brand;
        text-align: center;

        ul {
            display: block;
            margin: 0;
            padding: 0;
            list-style-type: none;
            text-align: center;

            li {
                display: inline-block;
                margin: 0;
                padding: 0;

                &:first-child {
                    margin-left: 45px;

                    @media (min-width: 320px) and (max-width: 412px) {
                        margin-left: 22px;
                    }

                    a {
                        padding: 0;
                    }

                }

                a {
                    transition: all 0.33s ease-in-out;
                    display: block;
                    color: $color-brand;
                    text-transform: uppercase;
                    font-size: 22px;
                    line-height: 24px;
                    padding: 0 15px;

                    @media (min-width: 320px) and (max-width: 412px) {
                        font-size: 18px;
                        padding: 0 12px;
                        line-height: 20px;
                    }

                    &:hover {
                        transition: all 0.33s ease-in-out;
                        color: $white;
                        text-decoration: none;
                    }
                }
            }
        }
    }

    .copyright {
        display: block;
        margin:0 auto;
        color: $color-brand;
        font-family: "freight-sans-pro";
        font-size: 10px;
        font-weight: 300;
        line-height: 13px;
        text-align: center;

        a {
            transition: all 0.33s ease-in-out;
            color: $color-brand;

            &:hover {
                transition: all 0.33s ease-in-out;
                color: $white;
                text-decoration: none;
            }
        }
    }
}
