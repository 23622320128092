.btn {
    transition: all 0.33s ease-in-out;
    display: inline-block;
    padding: 10px 30px 8px;
    text-decoration: none;
    background-color: $color-btn;
    color: $color-brand3;
    border-radius: 0;
    transition: background-color 0.2s;
    text-transform: uppercase;
    font-weight: 900;
    line-height: 18px;
    font-size: 12px;
    font-family: "brandon-grotesque";
    letter-spacing: 2.25px;

    &:not([href]):not([tabindex]) {
        color: $color-brand3;
    }

    &.view-more {
        float: right;
        margin: 7px 0;
        padding: 4px 6px 2px;
        color: $color-brand3;

        @include media-breakpoint-down(md) {
            margin-top: -1px;
        }
    }

    &:hover,
    &:focus {
        transition: all 0.33s ease-in-out;
        background-color: $color-btn;
        color: #fff;
    }

}
