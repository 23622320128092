.o-page-header {
    transition: all 0.33s ease-in-out;
    position: fixed;
    z-index: 99;
    width: 100%;
    padding: 26px 0;
    background-color: $color-brand3;

    @include media-breakpoint-down(md) {
        padding: 18px 0;
        text-align: center;
    }

    &.shrink {
        transition: all 0.33s ease-in-out;
        box-shadow: 0 5px 14px 9px rgba(12, 34, 56, 0.2);
    }

    .link--logo {
        height: 48px;
        width: 48px;

        @include media-breakpoint-down(md) {
            margin: 0 auto;
        }

        .link--logo--image {
            transition: all 0.33s ease-in-out;
            width: 100%;
            height: auto;
            max-width: 48px;

            #Style-Guide {
                transition: all 0.33s ease-in-out;
            }

            &:hover {
                transition: all 0.33s ease-in-out;

                #Style-Guide {
                    transition: all 0.33s ease-in-out;
                    fill: $white;
                }

            }
        }
    }

    .o-menu-wrapper {
        transition: all 0.33s ease-in-out;
        float: right;
        margin-top: 13px;

        @include media-breakpoint-down(md) {
            display: block;
            width: 100%;
            text-align: center;
            float: none;
        }

        .btn-menu-wrapper {
            display: none;
        }

        .nav__links {
            margin: 0;
            padding: 0;
            text-align: right;

            @include media-breakpoint-down(md) {
                display: block;
                width: 100%;
                text-align: center;
            }

            li {
                display: inline-block;

                &:last-child {
                    a {
                        padding-right: 0;
                    }
                }

                a {
                    transition: all 0.33s ease-in-out;
                    font-size: 16px;
                    font-family: "brandon-grotesque";
                    font-weight: 500;
                    letter-spacing: 3px;
                    text-transform: uppercase;
                    color: $white;
                    padding: 4px 15px 0px;

                    @include media-breakpoint-down(md) {
                        font-size: 12px;
                        padding: 4px 8px 0px;
                        letter-spacing: 2px;
                    }

                    @media (max-width: 375px) {
                        font-size: 12px;
                        padding: 4px 7px 0px;
                        letter-spacing: 1px;
                    }

                    @media (max-width: 320px) {
                        font-size: 12px;
                        padding: 4px 4px 0px;
                        letter-spacing: 1px;
                    }


                    &:hover {
                        transition: all 0.33s ease-in-out;
                        text-decoration: none;
                        color: $color-brand;
                    }
                }
            }
        }
    }
}
