img {
    max-width: 100%;
    vertical-align: middle;
}

.featured-image {
    margin-bottom: 30px;

    @include media-breakpoint-down(md) {
        margin-bottom: 30px;
    }
}
