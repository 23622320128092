form {
    input,
    input:focus,
    input:hover,
    input:active,
    textarea,
    textarea:focus,
    textarea:hover,
    textarea:active {
        transition: all 0.33s ease-in-out;
        outline: none !important;

        width: 100%;
        max-width: 290px;

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    input[type=text] {
        width: 100%;
        max-width: 290px;

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    textarea {
        transition: all 0.33s ease-in-out;
        background-color: transparent;
        border: 1px solid $white;
        padding: 4px 4px 4px 12px;
        color: rgba(255, 255, 255, 0.33);
        font-size: 16px;
        line-height: 26px;
        height: 144px;
        width: 100%;
        max-width: 290px;

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }

        &:focus {
            transition: all 0.33s ease-in-out;
            color: rgba(255, 255, 255, 1);
            border: 1px solid $color-brand;
        }
    }

    input:not([type=submit]):not([type=button]) {
        transition: all 0.33s ease-in-out;
        background-color: transparent;
        border: 1px solid $white;
        color: rgba(255, 255, 255, 0.33);
        padding: 4px 4px 4px 12px;
        font-size: 16px;
        line-height: 26px;
        width: 100%;
        max-width: 290px;

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }

        &:focus {
            transition: all 0.33s ease-in-out;
            color: rgba(255, 255, 255, 1);
            border: 1px solid $color-brand;
        }
    }

    label.required {
        display: inline-block;
        position: relative;

        &:after {
            display: block;
            content: '*';
            color: $white;
            position: absolute;
            top: 0;
            right: -10px;
        }
    }
}

.gform_body {
    ul.gform_fields {
        margin: 24px 0;
        padding: 0;
        list-style-type: none;

        li {
            margin-bottom: 24px;

            &:last-child {
                margin-top: 24px;
            }
        }

        .gfield_label {
            text-transform: uppercase;
            letter-spacing: 4px;
            font-weight: 600;
            color: $white;
            position: absolute;
            padding: 4px 0 0 12px;
            opacity: 0;
        }

        .gfield {
            .ginput_container_text {
                input {
                    width: 100%;
                }
            }

            .ginput_container_textarea {
                textarea {
                    width: 100%;
                }
            }

            .ginput_container_email {
                width: 100%;
            }
        }

        .gfield_label {
            .gfield_required {
                color: $white;
            }
        }
    }

    .validation_message {
        color: $color-brand;
    }
}

.gform_button {
    @extend .btn;
    display: inline-block;
    white-space: nowrap;
    user-select: none;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    width: auto;

    &:hover {
        text-decoration: none;
        width: auto;
    }

    @include media-breakpoint-down(sm) {
        width: 100%;
        float: none;
    }
}

.gform_fields {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.gfield_visibility_hidden {
    display: none;
}

.form__apply-now {
    display: block;
    width: 100%;
    margin-top: 60px;

    .attach_resume {
        width: 50%;
        float: right;

        @include media-breakpoint-down(sm) {
            width: 100%;
            float: none;
        }

        .gfield_label {
            display: none;
        }

        input:not([type=submit]):not([type=button]) {
            border-bottom: none;
        }

        input[type=file] {
            color: #fff;

            input[type=button] {
                display: inline-block;
                font-weight: 600;
                text-align: center;
                white-space: nowrap;
                vertical-align: middle;
                user-select: none;
                border: 2px solid transparent;
                padding: .375rem .75rem;
                font-size: 1rem;
                line-height: 1.5;
                border-radius: 0;
                transition: color 0.15s ease-in-out,
                    background-color 0.15s ease-in-out,
                    border-color 0.15s ease-in-out,
                    box-shadow 0.15s ease-in-out;
                color: #000;
                background-color: #fff;
                border-bottom: 2px solid #000;
                letter-spacing: 4px;
                text-transform: uppercase;

                &:hover {
                    color: $color-brand2;
                    text-decoration: none;
                    border-bottom: 2px solid $color-brand2;
                }
            }
        }

        input[type=button] {
            display: inline-block;
            font-weight: 600;
            text-align: center;
            white-space: nowrap;
            vertical-align: middle;
            user-select: none;
            border: 2px solid transparent;
            padding: .375rem .75rem;
            font-size: 1rem;
            line-height: 1.5;
            border-radius: 0;
            transition: color 0.15s ease-in-out,
            background-color 0.15s ease-in-out,
            border-color 0.15s ease-in-out,
            box-shadow 0.15s ease-in-out;
            color: #000;
            background-color: #fff;
            border-bottom: 2px solid #000;
            letter-spacing: 4px;
            text-transform: uppercase;

            &:hover {
                color: $color-brand2;
                text-decoration: none;
                border-bottom: 2px solid $color-brand2;
            }
        }

        .ginput_container_fileupload {
            position: relative;

            &:after{
                content: 'Upload Resume';
                position: absolute;
                display: inline-block;
                font-weight: 600;
                text-align: center;
                white-space: nowrap;
                vertical-align: middle;
                user-select: none;
                border: 2px solid transparent;
                padding: .375rem .75rem;
                font-size: 1rem;
                line-height: 1.5;
                border-radius: 0;
                transition: color 0.15s ease-in-out,
                background-color 0.15s ease-in-out,
                border-color 0.15s ease-in-out,
                box-shadow 0.15s ease-in-out;
                color: #000;
                background-color: #fff;
                border-bottom: 2px solid #000;
                letter-spacing: 4px;
                text-transform: uppercase;
                top: 0;
            }

            &:hover {
                cursor: pointer;
            }

            .medium {
                position: relative;
                z-index: 99;
                opacity: 0;

                &:hover {
                    cursor: pointer;
                }
            }
        }


        .screen-reader-text {
            display: none;
        }
    }

    .gform_footer {
        width: 50%;
        float: left;
    }
}



.gform_validation_container {
    display: none !important;
}




