@import url("https://p.typekit.net/p.css?s=1&k=fpy5imi&ht=tk&f=10302.10304.10954.13454.13460&a=4336486&app=typekit&e=css");

@font-face {
    font-family: "brandon-grotesque";
    src: url("https://use.typekit.net/af/d8f71f/0000000000000000000132e1/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("woff2"), url("https://use.typekit.net/af/d8f71f/0000000000000000000132e1/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("woff"), url("https://use.typekit.net/af/d8f71f/0000000000000000000132e1/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n5&v=3") format("opentype");
    font-style: normal;
    font-weight: 500;
}

@font-face {
    font-family: "brandon-grotesque";
    src: url("https://use.typekit.net/af/031bc7/0000000000000000000132e5/27/l?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n9&v=3") format("woff2"), url("https://use.typekit.net/af/031bc7/0000000000000000000132e5/27/d?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n9&v=3") format("woff"), url("https://use.typekit.net/af/031bc7/0000000000000000000132e5/27/a?primer=f592e0a4b9356877842506ce344308576437e4f677d7c9b78ca2162e6cad991a&fvd=n9&v=3") format("opentype");
    font-style: normal;
    font-weight: 900;
}

@font-face {
    font-family: "freight-sans-pro";
    src: url("https://use.typekit.net/af/442215/000000000000000000010b5a/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/442215/000000000000000000010b5a/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/442215/000000000000000000010b5a/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-style: normal;
    font-weight: 400;
}

@font-face {
    font-family: "freight-sans-pro";
    src: url("https://use.typekit.net/af/1709eb/000000000000000000010b60/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/1709eb/000000000000000000010b60/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/1709eb/000000000000000000010b60/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
    font-style: normal;
    font-weight: 700;
}

@font-face {
    font-family: "freight-sans-pro";
    src: url("https://use.typekit.net/af/cef9f3/000000000000000000010b5e/27/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"), url("https://use.typekit.net/af/cef9f3/000000000000000000010b5e/27/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"), url("https://use.typekit.net/af/cef9f3/000000000000000000010b5e/27/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
    font-style: normal;
    font-weight: 600;
}

.tk-brandon-grotesque {
    font-family: "brandon-grotesque", sans-serif;
}

.tk-freight-sans-pro {
    font-family: "freight-sans-pro", sans-serif;
}


@font-face {
    font-family: 'ms';
    src: url('assets/styles/fonts/ms/ms.eot?84871796');
    src: url('assets/styles/fonts/ms/ms.eot?84871796#iefix') format('embedded-opentype'),
        url('assets/styles/fonts/ms/ms.woff2?84871796') format('woff2'),
        url('assets/styles/fonts/ms/ms.woff?84871796') format('woff'),
        url('assets/styles/fonts/ms/ms.ttf?84871796') format('truetype'),
        url('assets/styles/fonts/ms/ms.svg?84871796#ms') format('svg');
    font-weight: normal;
    font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'ms';
    src: url('../font/isi.svg?57358980#isi') format('svg');
  }
}
*/

[class^="icon-"]:before,
[class*=" icon-"]:before {
    font-family: "ms";
    font-style: normal;
    font-weight: normal;
    speak: none;

    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */

    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;

    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;

    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;

    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */

    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-mail:before {
    content: '\e777';
}

/* '' */
.icon-spotify:before {
    content: '\e800';
}

/* '' */
.icon-shape:before {
    content: '\e801';
}

/* '' */
.icon-m_logo:before {
    content: '\e802';
}

/* '' */
.icon-icons8-google-play-music-filled:before {
    content: '\e803';
}

/* '' */
.icon-facebook:before {
    content: '\e804';
}

/* '' */
.icon-youtube:before {
    content: '\e805';
}

/* '' */
.icon-left-dir:before {
    content: '\e806';
}

/* '' */
.icon-right-dir:before {
    content: '\e807';
}

/* '' */
.icon-up-dir:before {
    content: '\e808';
}

/* '' */
.icon-down-dir:before {
    content: '\e809';
}

/* '' */
.icon-amazon-music:before {
    content: '\e80a';
}

/* '' */
.icon-facebook-official:before {
    content: '\f230';
}

/* '' */
.icon-mail-alt:before {
    content: '\f9e9';
}

/* '里' */
