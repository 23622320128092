// Color palette
$color-brand:           #A18843;
$color-brand2:          #0C2238;
$color-brand3:          #15395E;
$color-brand-highlight: lighten($color-brand, 15%);
$color-brand-shadow:    darken($color-brand, 15%);
$white:                 #fff;

$color-gray:            #666;
$color-gray-light:      #ccc;
$color-gray-dark:       #151515;

$color-highlight:       rgba(255, 255, 255, 0.05);
$color-shadow:          rgba(0, 0, 0, 0.05);


// UI
$color-ui:          $color-gray-light;

// Text
$color-text:        $white;

// Links
$color-link:        $color-brand2;
$color-link-hover:  $color-brand-shadow;

// Buttons
$color-btn:         $color-brand;
$color-btn-hover:   $color-brand-shadow;
