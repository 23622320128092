h1 {
    font-size: 40px;
    font-family: "brandon-grotesque";
    line-height: 56px;
    color: $color-brand;
    font-weight: 900;
    text-transform: uppercase;
    letter-spacing: 3px;

    @include media-breakpoint-down(md) {
        @include font-size(24px);
    }
}

h2 {
    @include font-size(28px);
    font-family: "brandon-grotesque";
    font-weight: 900;
    line-height: 40px;
    color: $color-brand;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 8px;

    a {
        color: $color-brand;

        &:hover {
            text-decoration: none;
        }
    }

    @include media-breakpoint-down(md) {
        @include font-size(20px);
    }
}

h3 {
    @include font-size(16px);
    font-family: "brandon-grotesque";
    font-weight: 900;
    text-transform: uppercase;
    color: $color-brand;
    letter-spacing: 2px;
}

h4 {
    @include font-size(16px);
    font-family: "brandon-grotesque";
    margin-bottom: 9px;
}

h5 {
    @include font-size(18px);
    margin-bottom: 2px;
}

h6 {
    @include font-size(16px);
}
