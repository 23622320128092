$header-height: 100px;
$header-height-mobile: 128px;

.o-layout-wrapper {
    flex: 1 0 auto;

    @include media-breakpoint-down(md) {
        display: block;
        height: auto;
    }
}

.o-page-content {
    margin-top: $header-height;
    position: relative;
    flex: 1;
    color: $color-text;
    font-size: 16px;
    font-family: "freight-sans-pro";
    font-weight: 300;
    letter-spacing: 0.5px;
    line-height: 26px;

    @include media-breakpoint-down(md) {
        text-align: center;
        margin-top: $header-height-mobile;
    }

    .featured-image {
        max-width: 120%;
        margin-top: 10px;

        &.no-mobile {
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }

        @include media-breakpoint-down(md) {
            max-width: 100%;
        }
    }

    &.news {
        h1 {
            margin-bottom: 0px;
        }

        .o-post-listings {
            list-style-type: none;
            margin: 0;
            padding: 0;

            .o-post-listing-item {
                color: $white;
                font-family: "freight-sans-pro";
                font-size: 16px;
                font-weight: 300;
                letter-spacing: 0.5px;
                line-height: 26px;
                margin-bottom: 30px;

                .o-post-listing-item__excerpt {
                    a {
                        color: $color-brand;

                        &:hover {
                            text-decoration: none;
                        }
                    }
                }

                .btn {
                    margin-top: 12px;
                }
            }
        }

    }

    &.music {
        .btn {
            @include media-breakpoint-down(md) {
                display: block;
                max-width: 57%;
                margin-left: auto;
                margin-right: auto;
                text-align: center;
            }

            &.alt {
                margin-bottom: 15px;
                margin-right: 30px;

                &.active {
                    color: $white;
                }

                @include media-breakpoint-down(md) {
                    margin-bottom: 18px;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }
    }

    &.video {
        .video-thumbs {
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 30px;
            }
        }

        .video-title {
            display: block;
            font-size: 16px;
            text-transform: uppercase;
            color: $color-brand;
            font-family: "brandon-grotesque";
            font-weight: 900;
            line-height: 23px;
            letter-spacing: 1.5px;
            margin-top: 12px;
        }
    }

    &.connect {
        .social__links {
            margin: 20px 0 40px;
            padding: 0;

            li {
                display: inline-block;
                padding: 0 8px;

                &:first-child {
                    padding-left: 0;
                }

                a {
                    transition: all 0.33s ease-in-out;
                    color: $color-brand;
                    font-size: 24px;
                    &:hover {
                        transition: all 0.33s ease-in-out;
                        color: $white;
                    }
                }
            }
        }
    }

    .primary-imagery {
        display: block;
        position: relative;
        width: 100%;
        margin-bottom: 70px;

        @include media-breakpoint-down(md) {
            overflow: hidden;
        }

        .home-graphic-01 {
            position: absolute;
            display: block;
            left: 0;
            top: 23%;
            z-index: 5;
            width: 100%;
            max-width: 740px;
            height: auto;

            @include media-breakpoint-down(md) {
                max-width: 238px;
            }
        }

        .featured-image {
            position: relative;
            display: block;
            right: -39%;
            width: 100%;
            max-width: 864px;
            height: auto;
            margin: 0;
            padding: 0;

            @include media-breakpoint-down(md) {
                width: 63%;
                right: -41%;
            }

        }
    }

    .home-graphic {
        width: 100%;
        height: auto;
    }

    .o-media-section {
        margin-bottom: 40px;
        margin-top: 60px;

        @include media-breakpoint-down(md) {
            text-align: center;
        }

        .col-md-5 {
            @include media-breakpoint-down(md) {
                margin-bottom: 30px;
            }
        }

        h2 {
            @include font-size(40px);
            display: inline-block;
            font-family: "brandon-grotesque";
            line-height: 56px;
            color: $color-brand;
            font-weight: 900;
            text-transform: uppercase;
            letter-spacing: 3px;

            @include media-breakpoint-down(md) {
                display: block;
            }
        }

        .btn {
            float: right;
            margin-top: 10px;

            @include media-breakpoint-down(md) {
                float: none;
                margin-bottom: 10px;
                display: none;
            }
        }

    }
}

.homepage-content {
    .order-first {
        @include media-breakpoint-down(sm) {
            order: 2;
        }
    }
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
}

.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.o-pagination {
    .page-numbers {
        padding: 10px;
        color: $color-brand;

        &:hover {
            text-decoration: none;
            color: $color-brand2;
        }

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        &.current {
            color: $white;
            font-weight: bold;
            cursor: pointer;

            &:hover {
                text-decoration: none;
                color: $color-brand2;
            }
        }

        &.next,
        &.prev {
            color: $color-brand2;
            font-weight: bold;

            &:hover {
                text-decoration: none;
                color: $color-brand;
            }
        }
    }

}

.popup-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: url('assets/images/bg-1920x1080.jpg') bottom left;
    background-size: cover;
    width: 100%;
    height: 100%;
    z-index:999;
    display: none;

    &.active {
        display: block;
    }

    .popup--logo--image {
        position: absolute;
        top: 0;
        left: 0;
        margin: 44px;
        width: 110px;
        height: auto;

        @include media-breakpoint-down(sm) {
            margin: 16px;
            width: 48px;
        }
    }

    .close-btn {
        position: absolute;
        z-index: 99;
        top: 80px;
        right: 0;
        font-family: "brandon-grotesque", sans-serif;
        font-weight: 500;
        text-transform: uppercase;
        color: $white !important;
        font-size: 16px;
        letter-spacing: 3px;
        padding-right: 20px;
        margin-right: 36px;


        @include media-breakpoint-down(sm) {
            top: 24px;
            margin-right: 8px;
        }

        &:after {
            content: '';
            position: relative;
            display: inline-block;
            width: 0;
            height: 0;
            right: -8px;
            border-style: solid;
            border-width: 6px 0 6px 12px;
            border-color: transparent transparent transparent $color-brand;
        }

        &:hover {
            cursor: pointer;
        }
    }

    .popup-content {
        position: absolute;
        width: 100%;
        text-align: center;
        padding: 80px 30px;
        margin: 0 auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include media-breakpoint-down(sm) {
            position: relative;
            top: 0;
            left: 0;
            transform: none;
            padding: 60px 48px 30px;
        }

        &.active {
            display: block;
        }

        .popup-image-container {
            position: relative;
            display: block;
            margin: 0 auto;
            width: 100%;
            max-width: 470px;

            @include media-breakpoint-down(sm) {
                max-width: 100%;
                //margin-top: 360px;
            }

            .popup-image {
                position: relative;
                display: block;
                margin: 0 auto;
                max-width: 470px;
                height: auto;

                @include media-breakpoint-down(sm) {
                    max-width: calc(100% - 0px);
                    margin-left: 40px;
                }
            }

            .popup-image-overlay {
                position: absolute;
                z-index: 99;
                display: block;
                max-width: 270px;
                height: auto;
                top: 12.5%;
                left: -3.5%;
            }
        }

        h2 {
            color: $white;
            display: block;
            font-size: 40px;
            font-family: "brandon-grotesque";
            font-weight: 900;

            @include media-breakpoint-down(sm) {
                font-size: 28px;
            }

            @media (max-width: 375px) {
                font-size: 20px;
            }
        }

        p{

            color: $white;
            font-size: 21px;
            letter-spacing: 0.5;

            @include media-breakpoint-down(sm) {
                font-size: 15px;
                letter-spacing: auto;
            }
        }

        h6 {
            text-transform: uppercase;
            color:$white;
            font-size: 24px;
            letter-spacing: 0.3px;
            font-family: "brandon-grotesque", sans-serif;
            font-weight: 900;

            @include media-breakpoint-down(sm) {
                margin-top: -12px;
            }

            @media (max-width: 375px) {
                font-size: 20px;
            }
        }

        .social-links {
            display: block;
            margin: 0 auto;

            ul.social__links {
                margin: 0;
                padding: 0;
                list-style-type: none;

                li {
                    display: inline-block;
                    padding: 0 4px;

                    &:first-child {
                        margin-right: 16px;
                    }

                    @include media-breakpoint-down(sm) {
                        padding: 0 5px;
                    }

                    a {
                        color: $color-brand;
                        font-size: 54px;

                        @include media-breakpoint-down(sm) {
                            font-size: 29px;
                            text-shadow: 0px 2px 5px rgba(0,0,0, 0.3);
                        }

                        @media (max-width: 375px) {
                            font-size: 24px;
                            text-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
                        }

                        &:hover {
                            color:$white;
                        }
                    }
                }
            }
        }
    }
}


.no-mobile {
    display: block;

    @include media-breakpoint-down(md) {
         display: none;
     }
}
